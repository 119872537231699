import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner02.png'
import img01 from '../../assets/img/Recruit/Recruit01.png'
import img02 from '../../assets/img/Recruit01/Recruit01_02.png'
import btn01 from '../../assets/img/Recruit/btn01.png'
import btn02 from '../../assets/img/Recruit/btn02.png'
import btn from '../../assets/img/Work01/work01btn.png'
import btn03 from '../../assets/img/Recruit/btn03.png'
import btn04 from '../../assets/img/Recruit/btn04.png'
import btn05 from '../../assets/img/Recruit/btn05.png'
import btn06 from '../../assets/img/Recruit/btn06.png'
import btn07 from '../../assets/img/Recruit/btn07.png'
import square from '../../assets/img/Work01/square.png'
import imgCom from '../../assets/img/Recruit/RecruitCom.png'

function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
    divStyle: {
      height: 'auto',
      width: '100%',
    },
    divBottom: {
      height: 'auto',
      width: '70%',
    },
    customTable: {
      marginTop: '5%',
      borderCollapse: 'collapse',
      fontFamily: 'noto Sans JP',
    },
    span: {
      backgroundColor: '#2085f6',
      color: 'white',
    },
    spanStyle: {
      width: '2%',
      height: '8%',
      paddingBottom: '1%',
    },
    imgSize: {
      width: '1px',
      height: '1px',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        {/* <script src="css/assets/jss/jquery.mCustomScrollbar.css"></script> */}
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"
        ></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script> */}
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{  color: 'rgb(16, 181, 236)'}}>-</h4>
        <h3 style={style.margin}>
          {' '}
          自信と誇りを持って、価値創出を追求するSI企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　採用情報</dt>
                <dd>
                  <a href="/recruit01">インフラ</a>
                </dd>
                <dd>
                  <a href="/recruit02">無線通信</a>
                </dd>
                <dd>
                  <a href="/recruit03">開発</a>
                </dd>
                <dd>
                  <a href="/contact">応募登録</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <div>
                  <img src={img01} className="recruitImg01" />
                </div>
                <div className="recruitTitle" style={{textAlign:'left',paddingTop:'2%'}}>
                  無線通信エンジニア/経験浅めOK/面接1回/前職より給与UPの実績多数あり/チームアサイン制/残業月10時間程度
                </div>
                <div className="recruitDetail" style={{color:'black'}}>
                <span className="recruitSpan01">
                  通信業界は昨今、激動の日々が続き、 企業の合併や基地局の新設が
                  急速に行われています。
                  <br />
                  ここには通信インフラ整備を急ぐ気持ちもありますが、
                  <br />
                  そこで働くエンジニアの気持ちに
                  しっかり目を向けられているのでしょうか？
                  <br />
                  <br />
                  少数精鋭で事業拡大を目指している当社は、
                  「社員がさみしくならず、個を尊重した職場作り」
                  を第一優先事項に掲げています。
                  <br />
                  <br />
                  そのために自社の経営基盤の確立や
                  就労環境の改善にとことん注力。
                  <br />
                  サムスン電子ジャパン株式会社や日本電気株式会社をはじめ
                  大手各社と取引をし、所属母体の安定性を保証します。
                  <br />
                  <br />
                  また、就労状況を常時確認し、残業過多に対して
                  営業がクライアントへの改善依頼を徹底。
                  <br />
                  優良な先輩エンジニアと共にプロジェクトに参画できるため、
                  スキル成長の安心感もお約束します。
                  <br />
                  <br />
                  「個を失った歯車を止め、 不安を抱えずに安心して働ける」
                  その実現のためにぜひ当社にお越しください。
                  <br />
                  </span>
                </div>

                <div style={style.divStyle}>
                  <table className="recruitTable">
                    <tr>
                      <td className="recruittd1" align="center">
                        募集背景
                      </td>
                      <td className="recruittd2">
                        元エンジニアの代表によって設立された当社。もとよりインフラ案件には強かったのですが、
                        会社の規模拡大、要望を受け、無線通信エンジニアが増加してきました。
                        今回も、当社の環境でストレス少なく働きたいエンジニアを大募集。
                        できる限り希望を叶える、エンジニアが一人で困ることがないような環境をご用意致します。
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        仕事内容
                      </td>
                      <td className="recruittd2">
                        ≪5G関連の最新プロジェクト多数≫<br></br>
                        ネットワーク構築、モバイル通信（4G・5G）の基地局インフラ構築などをお任せします。
                        <br></br>
                        ※あなたのスキル・希望に合った配属先を決定します。
                        <br></br>
                        <br></br>
                        数年単位の長期プロジェクトの中で、
                        設計・構築・検証・運用までの全工程に携われます。
                        <br></br>
                        原則チームで対応いただくため、 経験に自信がない方でも
                        <br></br>
                        先輩のサポートのもとでスキルアップ可能。<br></br>
                        そして、若手でも積極的にリーダー職をお任せしていく環境です。
                        <br></br>
                        <br></br>
                        <span style={{backgroundColor:'#0fd6db', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　開発環境・業務範囲　
                        </span>
                        <span>　</span>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　エンジニア限定取材　
                        </span>
                        <br></br>
                        <br></br>
                        ◆某大手携帯通信事業者の5Gネットワーク構築プロジェクト
                        <br></br>
                        概要：携帯5Gネットワーク構築の設計・検証試験等を担当
                        <br></br>
                        担当フェーズ：無線電波エリア設計や基地局内機器の設計・検証など
                        <br></br>
                        検証環境：サムスン製品、基地局機器・電波設計ツール
                        <br></br>
                        <br></br>
                        ◆某大手通信事業者の基地局運用監視<br></br>
                        概要：携帯5G、4Gネットワークの基地局について<br></br>
                        　　　異常発生時のエスカレーション対応<br></br>
                        担当業務：24365対応の交代勤務<br></br>
                        など
                        <br></br>
                        <br></br>
                        <span style={{backgroundColor:'#0fd6db', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　開発環境・業務範囲　
                        </span>
                        <span>　</span>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　エンジニア限定取材　
                        </span>
                        <br></br>
                        <br></br>
                        主な関連機器等／CISCO Router、Switch、Firewall、F5
                        BIG-IP、Juniper、LoadBalancer、Nexus、PaloAlto、Fortinet、A10など
                        <br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　注目　
                        </span>
                        　スキルアップ支援のための費用補助＆ラボ室開業予定
                        <br></br>
                        当社ではインフラ系エンジニアを対象に、入社時にCCNAの取得を推奨！書籍購入や受検費用は会社が負担します。また、代表が元インフラエンジニアのため、キャリアの先輩としても相談可能！さらに、社員の声に応えて自社に今後『ラボ室』と称して、実機を導入していきます。スキルUPのために積極的にご活用ください！
                        <br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　注目　
                        </span>
                        　パートナー企業と最先端案件にアサインも可能<br></br>
                        国際的に活躍するエンジニアが所属する企業とパートナーシップを結んでおり、経験が浅い方でもパートナー企業のベテランエンジニアと一緒に最先端の案件に入ることが可能！AIやIoTなど、この先の時代を形作る開発プロジェクトに設計などから入り経験を積むことができます。ストレスのない環境を手に入れられるだけでなく、技術向上も当社で叶えられます。
                        <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        応募資格
                      </td>
                      <td className="recruittd2">
                        ◆無線通信エンジニアの実務経験（年数不問）<br></br>
                        （設計や検証、運用まで幅広い案件があり、スキルに合わせてアサインします）
                        <br></br>
                        ◆学歴不問<br></br>
                        スキルアップできる環境やフォロー体制に定評があります！
                        <br></br>
                        経験豊富な先輩社員とチームアサインも可能。<br></br>
                        女性エンジニアも複数名活躍中です！<br></br>
                        <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        雇用形態
                      </td>
                      <td className="recruittd2">正社員</td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        想定給与
                      </td>
                      <td className="recruittd2">
                        月給27万円～80万円<br></br>
                        ★多くの方が前職を上回る給与で入社しています！ご期待ください
                        <br></br>
                        <br></br>
                        ※経験や能力、実績、前職給与を考慮の上、面談で話し合って決定します
                        <br></br>
                        ※上記には固定残業代（6万1720円～/35時間分）が含まれます
                        <br></br>
                        ※35時間超過の場合は別途残業代支給（平均残業は月10時間です）
                        <br></br>
                        ※試用期間（3ヶ月）の給与や待遇に差異はございません
                        <br></br>
                        <br></br>
                        ＜別途各種手当あり＞<br></br>
                        ◆交通費全額支給<br></br>
                        ◆出張手当<br></br>
                        ◆役職手当<br></br>
                        ◆職能手当<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　実際の給与例　
                        </span>
                        <br></br>
                        ≪年収例≫<br></br>
                        ◆年収710万円／45歳（入社9年）<br></br>
                        ◆年収580万円／37歳（入社5年）<br></br>
                        ◆年収415万円／31歳（入社3年）<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        勤務時間
                      </td>
                      <td className="recruittd2">
                        原則9:00～18:00（実働8時間／休憩60分）<br></br>
                        ※プロジェクトにより異なる場合あり<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　残業について　
                        </span>
                        <br></br>
                        平均10時間程度 <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        勤務地
                      </td>
                      <td className="recruittd2">
                        テレワーク約50％◎東京23区内や神奈川県川崎市などのクライアント先
                        <br></br>
                        <br></br>
                        ※通勤時間やプロジェクト内容の希望を考慮して決定<br></br>
                        ※転居を伴う転勤なし<br></br>
                        <br></br>
                        ＜本社＞<br></br>
                        東京都千代田区紀尾井町4-1<br></br>
                        ニューオータニガーデンコート22F<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　交通・詳細　
                        </span>
                        <br></br>
                        ＜本社＞<br></br>
                        東京メトロ丸ノ内線(赤坂見附駅)より徒歩5分<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　勤務地エリア　
                        </span>
                        <br></br>
                        東京都(新宿区)、神奈川県、埼玉県、千葉県<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        休日休暇
                      </td>
                      <td className="recruittd2">
                        ＜年休120日＞<br></br> ◆完全週休2日制（土日）<br></br>{' '}
                        ◆祝日<br></br>
                        ◆年末年始休暇（12月29日～1月3日）<br></br>{' '}
                        ◆夏季休暇（3日間）<br></br>
                        ◆慶弔休暇<br></br>
                        ◆有給休暇（11～20日/年 ※勤続年数1年以上）<br></br>
                        ◆産前・産後休暇<br></br> ◆育児休暇<br></br> ◆介護休暇
                        <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        待遇・福利厚生
                      </td>
                      <td className="recruittd2">
                        ◆昇給年1回（4月）<br></br>
                        ◆各種社会保険完備（雇用・労災・健康・厚生年金）<br></br>
                        ◆資格取得支援（合格時に受験料・講座費用を全額支給）
                        <br></br>
                        ◆社員旅行（年1回/1泊2日）<br></br>
                        ◆BBQ<br></br>
                        ◆誕生日プレゼント<br></br>
                        ◆服装自由　※勤務先により例外あり<br></br>
                        ◆交通費全額支給<br></br>
                        ◆出張手当<br></br>
                        ◆役職手当<br></br>
                        ◆職能手当<br></br>
                      </td>
                    </tr>
                  </table>
                </div>

                <div className="work04Img03div" style={{marginBottom:'-1%'}} >
                    <img src={img02} className="work04Img03" />
                </div>
                <a href="/contact" >
                <img src={btn} className="workBtn"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
